body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.panel-heading {
  padding: 1.25rem 1.5rem;
}

.panel-heading h2 {
  font-weight: 400;
  font-size: 1.9rem;
  margin-bottom: 0;
}

.tile {
    padding-top: 100%;
    background: green;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.product {
    /* background: linear-gradient( #e1e1e1,#f9f9f9); */
    border-radius: 6px;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    min-height: 200px;
    position: relative;
    margin-bottom: 15px;
}

.product-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
}

.product-title {
    font-weight: bold;
    font-size: 2rem;
    padding: 15px 0 0 15px;
}

.product-unit {
    padding: 0 15px;
}

.product-price {
    position: absolute;
    font-weight: 500;
    font-size: 1.8rem;
    right: 15px;
    bottom: 75px;
}

.product-amount {
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    display: inline-block;
    line-height: 30px;
    min-width: 35px;
    background: #fff;
    margin: 0 5px;
    text-align: center;
}

.product-buttons button:first-child {
    float: left;
}
.product-buttons button:last-child {
    float: right;
}

.category-tile {
    background: rgba(178, 151, 114, 0.8); 
    border: 0;
    border-radius: 4px;
    min-height: 200px;
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-tile:hover {
    background: rgba(178, 151, 114, 1);  
}

.category-title {
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 600;
    color: #fff;
    margin-top: 15px;
}

.login {
    padding: 15px;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-top: 20vh;
    width: 400px;
}

.login h2 {
    margin-top: 0;
}

.lost-password {
    margin-left: 15px;
}

.cart-container a {
    padding-right: 25px !important;
}

.cart-badge {
    background: #333;
    position: absolute;
    left: 14px;
    top: -5px;
}

.cart-total {
    border-top: 1px solid #c1c1c1;
    margin-bottom: 30px;
}

.cart-total,
.product-line {
    line-height: 21px;
}

.product-line > div {
    padding: 6px 9px;
}

.product-line:nth-child(even) {
    background: #f9f9f9;
}
.product-line h3 {
    background: #fff;
    margin-top: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e1e1e1;
}

.product-line-total {
    font-weight: 500;
}

#product-list {
    margin-bottom: 45px;
}

.product-actions-container {
    padding-top: 4px !important;
}

.product-actions {
    display: flex;
    flex-direction: row-reverse;
}
.orderHeader,
.order > .row{
    padding: 10px 10px;
}

.orderHeader {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}

.order-expanded > .row div:last-child {
    color: #fff !important;
}

.order > .row div:last-child {
    color: #777;
    font-size: 1.1rem;
    text-align: right;
}

.order-expanded {
    position: relative;
    top: -5px;
    left: -5px;
    box-shadow:  0px 3px 1px #999;
    background: white;
    margin-top: 5px;
    border: 1px solid #ddd;
    width: calc(100% + 10px);
}

.order-expanded > .row {
    font-size: 1.4rem;
}

.order-expanded {
    background: #337ab7 !important;
    border: 1px solid #337ab7;
    color: #fff;
}

.order > .row div:first-of-type {
    font-weight: bold;
}

.order:nth-child(odd) {
    background: #f6f6f6;
}

.orderDetails {
    background: #fff;
    padding: 10px 15px 25px 15px;
    color: #555;
    font-size: 1.3rem;
}

.orderDetails > .row {
    padding: 5px 0px;

}

.orderDetails .row:first-of-type {
    background: #eee;
    font-weight: bold;
    color: #000;
}


body,
html {
    height: 100%;
}

body.login-background {
    background: url('/background.jpg');
    background-size: cover;
    background-position: center center;
}

.login {
    background: #fff;
}
