body,
html {
    height: 100%;
}

body.login-background {
    background: url('/background.jpg');
    background-size: cover;
    background-position: center center;
}

.login {
    background: #fff;
}