.tile {
    padding-top: 100%;
    background: green;
    vertical-align: middle;
    position: relative;
    width: 100%;
}

.product {
    /* background: linear-gradient( #e1e1e1,#f9f9f9); */
    border-radius: 6px;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    min-height: 200px;
    position: relative;
    margin-bottom: 15px;
}

.product-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 15px;
}

.product-title {
    font-weight: bold;
    font-size: 2rem;
    padding: 15px 0 0 15px;
}

.product-unit {
    padding: 0 15px;
}

.product-price {
    position: absolute;
    font-weight: 500;
    font-size: 1.8rem;
    right: 15px;
    bottom: 75px;
}

.product-amount {
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    display: inline-block;
    line-height: 30px;
    min-width: 35px;
    background: #fff;
    margin: 0 5px;
    text-align: center;
}

.product-buttons button:first-child {
    float: left;
}
.product-buttons button:last-child {
    float: right;
}

.category-tile {
    background: rgba(178, 151, 114, 0.8); 
    border: 0;
    border-radius: 4px;
    min-height: 200px;
    position: relative;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-tile:hover {
    background: rgba(178, 151, 114, 1);  
}

.category-title {
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 600;
    color: #fff;
    margin-top: 15px;
}

.login {
    padding: 15px;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    margin-top: 20vh;
    width: 400px;
}

.login h2 {
    margin-top: 0;
}

.lost-password {
    margin-left: 15px;
}

.cart-container a {
    padding-right: 25px !important;
}

.cart-badge {
    background: #333;
    position: absolute;
    left: 14px;
    top: -5px;
}

.cart-total {
    border-top: 1px solid #c1c1c1;
    margin-bottom: 30px;
}

.cart-total,
.product-line {
    line-height: 21px;
}

.product-line > div {
    padding: 6px 9px;
}

.product-line:nth-child(even) {
    background: #f9f9f9;
}
.product-line h3 {
    background: #fff;
    margin-top: 30px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e1e1e1;
}

.product-line-total {
    font-weight: 500;
}

#product-list {
    margin-bottom: 45px;
}

.product-actions-container {
    padding-top: 4px !important;
}

.product-actions {
    display: flex;
    flex-direction: row-reverse;
}