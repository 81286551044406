.orderHeader,
.order > .row{
    padding: 10px 10px;
}

.orderHeader {
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
}

.order-expanded > .row div:last-child {
    color: #fff !important;
}

.order > .row div:last-child {
    color: #777;
    font-size: 1.1rem;
    text-align: right;
}

.order-expanded {
    position: relative;
    top: -5px;
    left: -5px;
    box-shadow:  0px 3px 1px #999;
    background: white;
    margin-top: 5px;
    border: 1px solid #ddd;
    width: calc(100% + 10px);
}

.order-expanded > .row {
    font-size: 1.4rem;
}

.order-expanded {
    background: #337ab7 !important;
    border: 1px solid #337ab7;
    color: #fff;
}

.order > .row div:first-of-type {
    font-weight: bold;
}

.order:nth-child(odd) {
    background: #f6f6f6;
}

.orderDetails {
    background: #fff;
    padding: 10px 15px 25px 15px;
    color: #555;
    font-size: 1.3rem;
}

.orderDetails > .row {
    padding: 5px 0px;

}

.orderDetails .row:first-of-type {
    background: #eee;
    font-weight: bold;
    color: #000;
}

